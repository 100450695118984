<template>
	<div class="bg-black p-6 min-h-screen">
		<div class="max-w-7xl mx-auto">
			<div class="lg:flex lg:space-x-6">
				<div class="w-full lg:w-4/5">
					<div class="video-responsive">
						<iframe :src="'https://www.youtube-nocookie.com/embed/AZvYN0faQUQ?rel=0'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>

					<div class="text-white font-thin text-center mt-10">
						<div>Gracias por acompañarnos.</div>
						<div>
							#LosMetro #SerEsSerVistx
						</div>
					</div>
				</div>

				<div class="hidden lg:block w-1/5 space-y-6">
					<a class="h-48 block" href="https://www.priceless.com" target="_blank">
						<img :src="require('@/assets/ads/ad1.jpg')" alt="">
					</a>
					<a class="h-48 block" href="https://cielitoquerido.com.mx" target="_blank">
						<img :src="require('@/assets/ads/ad2.jpg')" alt="">
					</a>
					<a class="h-48 block" href="https://www.teatrix.com" target="_blank">
						<img :src="require('@/assets/ads/ad3.jpg')" alt="">
					</a>
				</div>

				<div class="w-full md:w-2/5 lg:hidden mt-6 space-y-6 mx-auto px-6">
					<vueper-slides class="no-shadow" autoplay bullets-outside :arrows="false" :fixed-height="true">
						<vueper-slide :image="require('@/assets/ads/ad1.jpg')"></vueper-slide>
						<vueper-slide :image="require('@/assets/ads/ad2.jpg')"></vueper-slide>
						<vueper-slide :image="require('@/assets/ads/ad3.jpg')"></vueper-slide>
					</vueper-slides>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
	components: {
		VueperSlides, 
		VueperSlide,
	}
}
</script>

<style scoped>
    .video-responsive {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        padding-top: 30px;
        position: relative;
	}
    .video-responsive iframe, .video-responsive object, .video-responsive embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
	}
	.vueperslides--fixed-height { 
		height: 200px; 
	}
</style>
